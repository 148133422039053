.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  /* width: calc(100vw - 17px); */
  width: 100vw;
}

.banner {
  width: 100vw;
  height: 33.5417vw;
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  /* text-align: center; */
}

.bannerImg {
  /* width: calc(100vw - 17px); */
  width: 100vw;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.bannerTitle,
.bannerContent,
.bannerContentType2,
.bannerContainer {
  position: relative;
  color: #000;
  z-index: 1;
  user-select: none;
  top: 5%;
  width: 50%;
  text-align: center;
}

.bannerTitle {
  font-size: 2.9042vw;
  font-weight: 500;
  margin-top: 6vw;
}
.extraClass {
  margin-left: 13vw;
  margin-top: 7vw;
}
.bannerContent {
  font-size: 1.1375vw;
  width: 40vw;
  line-height: 2vw;
  margin: 1vw 0;
  margin-left: 5vw;
  text-align: left;
}
.bannerContentType2 {
  position: relative;
  font-size: 1.1375vw;
  width: 40vw;
  line-height: 2vw;
  /* background: #f0f8ff;
  border-radius: 0.5208vw;
  padding: 0.5208vw; 
  box-shadow: 0 0.1042vw 0.7813vw rgba(0, 0, 0, 0.12); */
  margin-left: 20vw;
  margin-top: 2vw;
  z-index: 10;
  text-align: left;
}

.bannerContentType2::after {
  content: '';
  /* width: 1.0417vw;
  height: 1.0417vw;
  background: #f0f8ff;
  transform: rotate(22.5deg);
  position: absolute;
  bottom: 8.5208vw;
  left: 30.0417vw; */
  box-shadow: 0 0.1042vw 0.2604vw rgba(0, 0, 0, 0.12);
  z-index: -10;
}

.bannerContainer {
  width: 10vw;
  /* margin: 0 auto; */
  margin-left: 18vw;
}
.bannerButton {
  padding: 0.4vw;
  border-radius: 1.6vw;
  font-size: 1.6vw;
  font-weight: 500;
  background-color: #1c2e64;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2vw;
  width: 10vw;
  cursor: pointer;
  margin: 1vw 0vw 0vw;
}
.bannerButtonText {
  margin-top: -0.2vw;
}
.bannerButtonImgDiv {
  width: 1.8vw;
  height: 1.8vw;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5vw;
}
.bannerButtonImg {
  width: 1.1vw;
  height: 1.1vw;
  border-radius: 50%;
}
.bannerQrCode {
  width: 10.8vw;
  margin-top: -4vw;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.bannerContainer:hover .bannerQrCode {
  opacity: 1;
}
.service {
  position: relative;
  /* background: linear-gradient(to bottom right, #006bc2, #34bced); */
}
.serviceBg {
  position: absolute;
  width: 100vw;
  height: 46.0417vw;
  top: 0;
  left: 0;
  z-index: -1;
}
.serviceTitle {
  position: relative;
  display: inline-block;
  padding: 0 1.0417vw;
  line-height: 4.1667vw;
  font-size: 2vw;
  font-weight: 700;
  color: #1c2e64;
  margin: 0 0 0.7813vw;
}

.serviceTitle::before,
.serviceTitle::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 14.1667vw;
  height: 0.0521vw;
  background-color: #dcdfe6;
  transform: translateY(-50%);
}
.serviceTitle::before {
  right: 13.6042vw;
}

.serviceTitle::after {
  left: 13.6042vw;
}
.serviceButtonsList {
  display: flex;
  justify-content: center;
}
.serviceButton {
  width: 8vw;
  height: 8vw;
  font-size: 1.0417vw;
  font-weight: 500;
  color: #000;
  border-radius: 0.9604vw;
  border: 0.3042vw solid #00a0e9;
  margin: 0 2.5208vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}
.serviceButtonIcon {
  width: 4.125vw;
  height: 4.125vw;
  /* background-color: #fff; */
  margin-bottom: 1vw;
}
.serviceButtonActive {
  border: 0.3042vw solid #fff;
  color: #1c2e64;
  border-color: #0a3d8d;
  font-weight: 700;
  color: #0a3d8d;
}
.serviceCarousel {
}
.serviceSlider {
  width: 70vw;
  margin: 1.5625vw auto;
  border-radius: 2.6042vw;
  margin-bottom: 0;
}
.serviceSliderItem {
  width: 70vw;
  height: 31vw;
  border-radius: 2.6042vw;
  position: relative;
}
.serviceSliderItemImg {
  width: 70vw;
  height: 27vw;
  border-radius: 2.6042vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.serviceSliderItemContent {
  margin-left: 1.2042vw;
  margin-right: auto;
  width: auto;
  text-align: start;
  color: #fff;
  display: flex;
}
.serviceSliderItemDescription {
  font-size: 1.942vw;
  font-weight: 700;
  margin-top: 5.8125vw;
  width: 30vw;
}
.serviceSliderItemPoints {
  width: 30vw;
}
.serviceSliderItemPoint {
  font-size: 1.1042vw;
  font-weight: 500;
  margin-top: 1.5625vw;
}
.serviceSliderItemSmallImg {
  width: 35vw;
  height: 18vw;
  border-radius: 2.6042vw;
  margin: 5vw 0 0 0;
}
.serviceList {
  display: flex;
  width: 80vw;
  justify-content: space-between;
  margin: 0 auto 0;
}
.serviceItem {
  width: 25.3958vw;
  box-shadow: 0 0 0.2083vw rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.serviceItemTop {
  width: 25.3958vw;
  height: 19vw;
  position: relative;
}
.serviceItemImg {
  width: 25.3958vw;
  height: 19vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.serviceItemTitle {
  position: absolute;
  left: 50%;
  top: 42%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 1.8417vw;
  font-weight: 700;
  white-space: nowrap;
  z-index: 2;
}
.serviceItemTop::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(48, 72, 137, 0.849);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 1;
}

.serviceItemTop::after {
  content: '';
  position: absolute;
  bottom: 40%;
  left: 31%;
  height: 0.2604vw;
  width: 0;
  background-color: #fff;
  transition: width 0.3s ease;
  z-index: 1;
}

.serviceItem:hover .serviceItemTop::before {
  opacity: 1;
}

.serviceItem:hover .serviceItemTop::after {
  width: 9.542vw;
}

.serviceItem:hover .serviceItemTitle {
  color: #fff; /* Optionally change text color on hover */
}
.serviceItemContent {
  padding: 0.5208vw;
  font-size: 1.1375vw;
  line-height: 1.5458vw;
  height: 8.9167vw;
  font-weight: 400;
  text-align: left;
}
.popularTitle {
  position: relative;
  display: inline-block;
  padding: 0 1.0417vw;
  line-height: 4.1667vw;
  font-size: 2vw;
  font-weight: 700;
  color: #1c2e64;
  margin-top: 0.7813vw;
}

.popularTitle::before,
.popularTitle::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 14.1667vw;
  height: 0.0521vw;
  background-color: #dcdfe6;
  transform: translateY(-50%);
}
.popularTitle::before {
  right: 39.042vw;
}

.popularTitle::after {
  left: 39.042vw;
}
.popularSmallTitle {
  font-size: 1.2625vw;
  font-weight: 400;
  color: #1c2e64;
  margin-top: 0.1vw;
  z-index: 999;
  background-color: #fff;
  height: 2vw;
  width: 28vw;
  margin: 0 auto;
  position: relative;
}
.popularImgList {
  width: 80vw;
  display: flex;
  flex-wrap: wrap;
  margin: 2vw auto;
  justify-content: center;
  border: 0.1042vw solid #000;
  margin-top: -1.1vw;
  z-index: 1;
  padding: 2vw 0;
  user-select: none;
}
.popularImgItem {
  width: 5vw;
  height: auto;
  margin: 0vw 5vw;
}
.contact {
  width: 90vw;
  padding: 5vw;
  user-select: none;
  background-color: #f5f6f8;
}
.contactTop {
  display: flex;
  align-items: center;
}
.contactLogo {
  width: 5.1667vw;
  height: 5.1667vw;
  margin-right: 1vw;
}
.contactBottom {
  display: flex;
  margin-top: 2vw;
  justify-content: space-between;
}
.contactBottomLeft {
  font-size: 1.2625vw;
  font-weight: 400;
  color: #1c2e64;
  margin-top: 0.1vw;
  width: 40vw;
  text-align: left;
}
.contactBottomCenter {
  text-align: left;
  margin-top: -5vw;
  margin-left: -3vw;
}
.contactUsTitle {
  font-size: 1.625vw;
  font-weight: 700;
  color: #1c2e64;
  margin-bottom: 2vw;
}
.contactFunc {
  font-size: 1.025vw;
  font-weight: 400;
  color: #1c2e64;
}
.contactBottomRight {
  display: flex;
}
.contactImg {
  width: 10.1667vw;
  height: 10.1667vw;
}
.contactConetnt {
  font-size: 1.2625vw;
  font-weight: 400;
  color: #000;
}
.contactTitle {
  font-size: 2.2625vw;
  font-weight: 800;
  color: #1c2e64;
  margin-top: 0.1vw;
}
.float-container {
  position: relative;
}
.float {
  position: fixed;
  top: 10vw;
  right: 0;
  font-size: 0.9813vw;
  /* padding: 0.5604vw; */
  background-color: #fff;
  box-shadow: 0 0 0.2083vw rgba(0, 0, 0, 0.1);
  font-weight: 700;
  width: 6vw;
  height: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.floatImg {
  width: 4vw;
}
.floatqrcode {
  position: fixed;
  top: 10vw;
  right: 6vw;
  width: 6vw;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.float-container:hover .floatqrcode {
  opacity: 1;
}
.info {
  margin: 2.6042vw auto;
  width: 65.9167vw;
  display: flex;
  justify-content: space-between;
  user-select: none;
}
.infoBorder {
  width: 0.0521vw;
  height: 5.3333vw;
  background-color: #1c2e64;
}
.infoItemNum {
  color: #1c2e64;
  font-weight: 700;
  font-size: 1.6542vw;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
.numericPart {
  font-size: 2.833vw;
}
.infoItemText {
  font-size: 1.1333vw;
  line-height: 1;
  color: #333;
  padding-top: 0.5208vw;
}
.reason {
  margin: 0 auto;
  width: 70vw;
  padding: 0 15vw 2vw;
  /* background-color: #6bceff; */
}
.reasonTitle {
  position: relative;
  display: inline-block;
  padding: 0 1.0417vw;
  line-height: 4.1667vw;
  font-size: 2vw;
  font-weight: 700;
  color: #1c2e64;
  margin-top: 0.7813vw;
}
.reasonTitle::before,
.reasonTitle::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 14.1667vw;
  height: 0.0521vw;
  background-color: #dcdfe6;
  transform: translateY(-50%);
}
.reasonTitle::before {
  right: 17.6042vw;
}

.reasonTitle::after {
  left: 17.6042vw;
}
.reasonSmallTitle {
  font-size: 1vw;
  font-weight: 500;
  color: #1c2e64;
}
.reasonList {
  display: flex;
  justify-content: space-between;
}
.reasonListLeft,
.reasonListRight {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.reasonListLeft {
}
.reasonListCenter {
  width: 23.4167vw;
  height: 23.4167vw;
  border-radius: 50%;
  border: 0.0521vw solid #c6c6c6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto 5.8125vw;
}
.reasonListImg {
  width: 21vw;
  height: 21vw;
  border-radius: 50%;
}
.reasonListRight {
  justify-content: space-between;
  padding: 4vw 0;
}
.reasonContent {
  display: flex;
  margin-top: 2.6042vw;
}
.reasonContentLeftImg {
  width: auto;
  height: 20.4583vw;
  border-radius: 0.625vw;
  /* background-color: pink; */
}
.reasonContentRightList {
  display: flex;
  flex-direction: column;
  margin-left: 3.5625vw;
  justify-content: space-between;
  padding: 1vw 0;
}
.reasonItem2 {
  text-align: start;
}
.reasonItemTitle2 {
  font-size: 1.175vw;
  font-weight: 700;
  border-bottom: 0.2042vw solid #1c65ff;
  display: inline-block;
}

.reasonItemContent2 {
  width: 26.4375vw;
  font-size: 0.8375vw;
  color: #000;
  margin-top: 0.108vw;
}
.reasonItem {
  margin-bottom: 1.0417vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.reasonItemRight {
  align-items: flex-start; /* 默认对齐方式 */
}
.reasonItemImg {
  width: 5.7292vw;
  height: auto;
  padding-bottom: 0.2604vw;
  margin-bottom: 0.2604vw;
  border-bottom: 0.2604vw solid #f29500;
}
.reasonItemTitle {
  font-size: 1.2375vw;
  color: #1787d3;
  margin-bottom: 0.2604vw;
  font-weight: 700;
}
.reasonItemContent {
  font-size: 1.1813vw;
  width: 11.8125vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: left;
}
.subject {
  width: 90vw;
  margin: 0 auto;
  /* background-color: #f0f5f8; */
  background-color: #def1fb;
  z-index: -1;
  padding: 0 5vw 2vw;
}

.subjectTitle {
  position: relative;
  display: inline-block;
  padding: 0 1.0417vw;
  line-height: 4.1667vw;
  font-size: 2vw;
  font-weight: 700;
  color: #1c2e64;
  margin-top: 0.7813vw;
}
.subjectTitle::before,
.subjectTitle::after {
  content: '';
  position: absolute;
  top: 50%;
  width: 14.1667vw;
  height: 0.0521vw;
  background-color: #dcdfe6;
  transform: translateY(-50%);
}
.subjectTitle::before {
  right: 33.042vw;
}

.subjectTitle::after {
  left: 33.042vw;
}
.subjectSmallTitle {
  font-size: 1vw;
  font-weight: 500;
  color: #1c2e64;
  margin: 0 auto;
}
.subjectList {
  display: flex;
  justify-content: space-between;
  width: 90vw;
}
.subjectItem {
  width: 17vw;
  height: 25vw;
  /* background-color: #fff; */
  /* background-color: #f0f5f8; */
  border-top-left-radius: 0.8333vw;
  border-top-right-radius: 0.8333vw;
  margin-top: 2vw;
  position: relative;
}
.subjectItemBg {
  width: 17vw;
  height: 25vw;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 0.8vw;
  object-fit: cover;
  object-position: top;
}
.subjectItemTop {
  height: 9vw;
  background-color: #4d81c4;
  border-top-left-radius: 0.8333vw;
  border-top-right-radius: 0.8333vw;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subjectItemTopBg {
  position: absolute;
  width: 17vw;
  height: 9vw;
  background-color: #4d81c4;
  border-top-left-radius: 0.8333vw;
  border-top-right-radius: 0.8333vw;
  z-index: -1;
}
.subjectItemTopIcon {
  width: 2.6042vw;
  height: 2.6042vw;
  background-color: #00a0e9;
}
.subjectItemTopTitle {
  font-size: 1.2375vw;
  color: #fff;
  margin-top: 0.604vw;
  font-weight: 700;
}

.subjectItemBottom {
  text-align: left;
  padding-left: 1.813vw;
  padding-top: 10.2813vw;
}

.subjectItemBottomNum {
  font-size: 1.6375vw;
  color: #0a3d8d;
  font-weight: 700;
  margin: 0.2vw 0 0.2vw;
}

.subjectItemBottomTitle {
  font-size: 1.0375vw;
  color: #000;
  font-weight: 500;
}
